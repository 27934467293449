import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {CollectionItemMoreOptionsPanelProps} from '@Panels/collection-item-more-options-panel/collection-item-more-options-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'collection-item-more-options-modal-panel';

export type CollectionItemMoreOptionsModalProps = CollectionItemMoreOptionsPanelProps;

export default function CollectionItemMoreOptionsModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.COLLECTION_ITEM_MORE_OPTIONS_MODAL];
  });

  return (
    <Modal modalId={MODALS_IDS.COLLECTION_ITEM_MORE_OPTIONS_MODAL} panelId={PANEL_ID} modalHeight="fit-content" modalWidth="100%">
      <LazyPanel
        panelDirectoryName="collection-item-more-options-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openCollectionItemMoreOptionsModal = (props: CollectionItemMoreOptionsModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.COLLECTION_ITEM_MORE_OPTIONS_MODAL,
      panelProps: props,
    })
  );
};
