import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {Button, Size, Type} from '@Components/button';
import styles from './error-boundary-fallback-container.module.scss';

interface ErrorBoundaryFallbackContainerProps {
  onReload(): void;
}

export function ErrorBoundaryFallbackContainer({...props}: ErrorBoundaryFallbackContainerProps): ReactElement {
  const getFallbackUI = (): ReactElement => {
    return (
      <div className="_full-width _full-height flex-column-justify-center flex-items-center spacing-p-l-5 spacing-p-r-5">
        <Icon
          icon="icon-exclamation-triangle"
          size={IconSize.SIZE_ICON_24}
          type={IconType.FLAT}
          shape={IconShape.CIRCLE}
          flatIconType={{
            color: FlatIconColor.NEUTRAL,
            type: FlatIconColorType.DEFAULT,
          }}
          className="spacing-m-b-2"
        />
        <Text className={styles.centerAlignText} size={TextSize.SMALL} val={window.i18next.t('pmwjs_default_error_error_boundary')} />
        <Button
          onClick={(): void => {
            props.onReload();
          }}
          customClasses="spacing-m-t-3"
          size={Size.SMALL}
          type={Type.SECONDARY}
          text={window.i18next.t('pmwjs_reload')}
          icon="icon-refresh"
        />
      </div>
    );
  };

  return getFallbackUI();
}
