import {useEffect, useState} from 'react';

interface ViewportOffset {
  viewportOffsetTop?: number;
  viewportOffsetLeft?: number;
}

const useViewportOffset = (): ViewportOffset => {
  const [viewportOffset, setViewportOffset] = useState({
    viewportOffsetLeft: window.visualViewport ? window.visualViewport.offsetLeft : undefined,
    viewportOffsetTop: window.visualViewport ? window.visualViewport.offsetTop : undefined,
  });

  useEffect(() => {
    if (window.visualViewport) {
      const scrollHandler = (): void => {
        setViewportOffset({
          viewportOffsetLeft: window.visualViewport ? window.visualViewport.offsetLeft : undefined,
          viewportOffsetTop: window.visualViewport ? window.visualViewport.offsetTop : undefined,
        });
      };

      window.visualViewport.addEventListener('scroll', scrollHandler, {passive: true});

      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener('scroll', scrollHandler);
        }
      };
    }
    return () => {};
  }, []);

  return viewportOffset;
};

export default useViewportOffset;
