import type React from 'react';
import type {AnimatedSprite} from '@Libraries/animated-sprite.library';

export interface ManagePmwStockPanelProps {
  searchTerm: string;
  startSavingItems: boolean;
  noKeywordsFilter: boolean;
  panelId?: string;
  showHiddenFilter: boolean | null;
  showPanel: string;
  categoryFilter?: Array<string> | null;
  sortByOption: string;
  usedForMaskingFilter?: boolean | null;
  areFiltersDisabled?: boolean;
  setAreFiltersDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableSave: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum ManagePMWStockPanelType {
  PMW_ALL_STOCK = 'PMW-ALL-STOCK',
  PMW_SHAPES = 'PMW-SHAPES',
  PMW_ANIMATED_STICKERS = 'PMW-ANIMATED-STICKERS',
  PMW_STILL_STICKERS = 'PMW-STILL-STICKERS',
  PMW_ICONS = 'PMW-ICONS',
  PMW_EMOJIS = 'PMW-EMOJIS',
  PMW_IMAGES = 'PMW-IMAGES',
  PMW_EXTRACTED_GETTY_STICKERS = 'PMW-EXTRACTED-GETTY_STICKERS',
}

export enum PMWStockItemType {
  SHAPE = 'shape',
  ANIMATED_STICKER = 'animated_sticker',
  STILL_STICKER = 'still_sticker',
  ICON = 'icon',
  EMOJI = 'emoji',
  IMAGE = 'image',
  EXTRACTED_GETTY_STICKER = 'extracted_getty_sticker',
}

export enum SortByOption {
  AscendingAdminScore = 'admin_score_asc',
  DescendingAdminScore = 'admin_score_desc',
  AscendingUploadDate = 'uploaded_on_asc',
  DescendingUploadDate = 'uploaded_on_desc',
}

export interface PMWStockBackendResult {
  l: Array<PMWStockBackendData>;
}

export interface PMWStockMediaSearchParams {
  isManageappRequest: number;
  st: string;
  types?: string;
  noKeywords: boolean;
  showHidden?: boolean | null;
  isUsedForMasking?: number;
  iconCategories?: Array<string> | null;
  sortBy: string;
  limit: number;
  offset: number;
}

export interface PMWStockBackend {
  idPmwStockMedia: string;
  adminScore: number;
  isHidden: number;
  uploadedOn: number;
  keywords: Array<string>;
  isMask: number;
  type: string;
}

export interface PMWStockBackendAnimatedSticker extends PMWStockBackend {
  idPMWStockAnimatedSticker: string;
  duration: number;
  frameRate: number;
  hashedFilename: string;
  highResAnimatedSprite: AnimatedSprite;
  screenAnimatedSprite: AnimatedSprite;
}

export interface PMWStockBackendShape extends PMWStockBackend {
  idPMWStockShape: string;
}

export interface PMWStockBackendStillSticker extends PMWStockBackend {
  idPMWStockStillSticker: string;
  hashedFilename: string;
}

export interface PMWStockBackendExtractedGettySticker extends PMWStockBackend {
  idPMWExtractedGettySticker: string;
  hashedFilename: string;
  originalSheetId: string;
}

export interface PMWStockBackendImage extends PMWStockBackend {
  idPMWStockImage: string;
  hashedFilename: string;
  width: number;
  height: number;
}

export interface PMWStockBackendEmoji extends PMWStockBackend {
  idPMWStockEmoji: string;
  hashedFilename: string;
}

export interface PMWStockBackendIcon extends PMWStockBackend {
  idPMWStockIcon: string;
  hashedFilename: string;
  category: string;
}

export type PMWStockBackendData =
  | PMWStockBackendShape
  | PMWStockBackendIcon
  | PMWStockBackendStillSticker
  | PMWStockBackendImage
  | PMWStockBackendEmoji
  | PMWStockBackendAnimatedSticker
  | PMWStockBackendExtractedGettySticker;

export const GRID_ITEM_MAX_WIDTH = 325;
export const GRID_ITEM_ASPECT_RATIO = 2.7;
export const LOAD_ITEM_BATCH_SIZE = 50;
