import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {MODALS_IDS} from '@Components/modal-container';
import {MOBILE_MODAL_WIDTH_THRESHOLD, Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import type {AddMailingListPanelProps} from '@Panels/add-mailing-list-panel';
import {PANEL_ID} from '@Modals/add-mailing-list-modal/index';
import useWindowSize from '@Hooks/useWindowSize';
import {useAppSelector} from '@/hooks';

export type AddMailingListModalProps = AddMailingListPanelProps;

const ADD_MAILING_LIST_MODAL_MAX_WIDTH_PX = 852;
const ADD_MAILING_LIST_MODAL_MAX_HEIGHT_PX = 762;

export default function AddMailingListModal(): ReactElement | null {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.ADD_MAILING_LIST_MODAL];
  });
  const isPanelLoading = useAppSelector((state) => {
    return state.lazyPanels.lazyPanelHashmap[PANEL_ID]?.isLoading ?? false;
  });
  const [height, setHeight] = useState<string>('');
  const [width, setWidth] = useState<string>('');

  const {windowWidth} = useWindowSize();

  const isMobileModal = (): boolean => {
    return windowWidth < MOBILE_MODAL_WIDTH_THRESHOLD;
  };

  useEffect(() => {
    if (isPanelLoading) {
      setHeight('457px');
      setWidth(isMobileModal() ? '100%' : '852px');
    } else {
      setHeight('auto');
      setWidth(isMobileModal() ? '100%' : 'auto');
    }
  }, [isPanelLoading]);

  const maxWidth = `${ADD_MAILING_LIST_MODAL_MAX_WIDTH_PX}px`;
  const maxHeight = `${ADD_MAILING_LIST_MODAL_MAX_HEIGHT_PX}px`;

  return (
    <Modal modalId={MODALS_IDS.ADD_MAILING_LIST_MODAL} panelId={PANEL_ID} modalMaxWidth={maxWidth} modalWidth={width} modalHeight={height} modalMaxHeight={maxHeight}>
      <LazyPanel
        lazyPanelId={PANEL_ID}
        panelDirectoryName="add-mailing-list-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}
