import {useEffect} from 'react';
import {closeModal} from '@Components/modal-container/modal-container-reducer';
import {hidePanel, triggerPanelClose} from '@Components/panel/panel-reducer';
import {useAppDispatch, useAppSelector} from '@/hooks';

const useModalClose = (panelId: string, modalId: string) => {
  const dispatch = useAppDispatch();
  const panelClosed = useAppSelector((state) => {
    return state.panels.panelHashmap[panelId]?.closed ?? false;
  });
  const hideOnClose = useAppSelector((state) => {
    return !!state.panels.panelHashmap[panelId]?.hideOnClose;
  });

  useEffect(() => {
    if (panelClosed) {
      doCloseModal();
    }
  }, [panelClosed]);

  const doCloseModal = () => {
    if (!modalId) {
      throw new Error('Modal id not defined');
    }

    dispatch(closeModal(modalId));
  };

  return (): void => {
    if (hideOnClose) {
      dispatch(hidePanel(panelId));
    } else {
      dispatch(triggerPanelClose(panelId));
    }
  };
};

export default useModalClose;
