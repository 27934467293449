import type {
  AJAXResponseProductData,
  DisplayType,
  PosterPublishingOptionsReduxState,
  ProductType,
} from '@Panels/poster-publishing-options-panel/poster-publishing-options-panel.types';
import {initialOrderDetails, SelectionType, ThumbNailType} from '@Panels/poster-publishing-options-panel/poster-publishing-options-panel.types';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {SubFlowType} from '@Panels/poster-publishing-options-panel/components/publishing-digital-signage-list/components/digital-signage-compiled-sub-panel/digital-signage-compiled-sub-panel.types';
import type {OrderDetailsForRedux} from '@Panels/poster-publishing-options-panel/components/publishing-embed-in-webpage-panel/publishing-embed-in-webpage-panel.types';
import type {SocialMediaResponse} from '@Panels/poster-publishing-options-panel/components/publishing-social-media-panel/publishing-social-media-panel.types';

const initialState: PosterPublishingOptionsReduxState = {
  thumbnailType: ThumbNailType.ICON,
  displayType: undefined,
  selectionType: SelectionType.DEFAULT_DISPLAY,
  subFlowType: SubFlowType.DEFAULT,
  orderDetails: initialOrderDetails,
  premiumProductDataForPrint: {} as AJAXResponseProductData,
  freebieProductData: {} as AJAXResponseProductData,
  premiumProductDataForPoster: {} as AJAXResponseProductData,
  productType: '' as unknown as ProductType,
  socialMediaLinks: {} as SocialMediaResponse,
  showError: false,
  errorMessage: 'Some error occured. Please try again.',
  showLoader: false,
  hashedId: '',
};

const posterPublishingOptionsSlice = createSlice({
  name: 'posterPublishingOptionsSlice',
  initialState,
  reducers: {
    setThumbnailType: (state, action: PayloadAction<ThumbNailType>): void => {
      state.thumbnailType = action.payload;
    },

    setDisplayType: (state, action: PayloadAction<DisplayType>): void => {
      state.displayType = action.payload;
    },

    setSelectionType: (state, action: PayloadAction<SelectionType>): void => {
      state.selectionType = action.payload;
    },

    setOrderDetails: (state, action: PayloadAction<OrderDetailsForRedux>): void => {
      state.orderDetails.progressPercentage = action.payload.progressPercentage;
      state.orderDetails.estimatedTimeInSeconds = action.payload.estimatedTimeInSeconds;
      state.orderDetails.timeElapsedInSeconds = action.payload.timeElapsedInSeconds;
    },

    setSocialMediaLinks: (state, action: PayloadAction<SocialMediaResponse>): void => {
      state.socialMediaLinks.fb = action.payload.fb;
      state.socialMediaLinks.posterName = action.payload.posterName;
      state.socialMediaLinks.pt = action.payload.pt;
      state.socialMediaLinks.ptUrl = action.payload.ptUrl;
      state.socialMediaLinks.tw = action.payload.tw;
    },

    setOrderError: (state, action: PayloadAction<string>): void => {
      state.orderDetails.error = action.payload;
    },

    setErrorOnPublishPanel: (state, action: PayloadAction<boolean>): void => {
      state.showError = action.payload;
    },

    setErrorMessageForPublishing: (state, action: PayloadAction<string>): void => {
      state.errorMessage = action.payload;
    },

    setPremiumProductForPoster: (state, action: PayloadAction<AJAXResponseProductData>): void => {
      state.premiumProductDataForPoster = action.payload;
    },

    setPremiumProductForPrint: (state, action: PayloadAction<AJAXResponseProductData>): void => {
      state.premiumProductDataForPrint = action.payload;
    },

    setFreebieProduct: (state, action: PayloadAction<AJAXResponseProductData>): void => {
      state.freebieProductData = action.payload;
    },

    setProductType: (state, action: PayloadAction<ProductType>): void => {
      state.productType = action.payload;
    },

    setShowLoader: (state, action: PayloadAction<boolean>): void => {
      state.showLoader = action.payload;
    },

    digitalSignageFlow: (state): void => {
      state.selectionType = SelectionType.DIGITAL_SIGNAGE_DISPLAY;
    },

    shareToAppFlow: (state): void => {
      state.selectionType = SelectionType.SHARE_TO_APP;
    },

    webpagePublishFlow: (state): void => {
      state.selectionType = SelectionType.PUBLISH_WEBPAGE_DISPLAY;
    },

    webpageEmbedFlow: (state): void => {
      state.selectionType = SelectionType.EMBED_WEBPAGE_DISPLAY;
    },

    socialMediaFlow: (state): void => {
      state.selectionType = SelectionType.SOCIAL_MEDIA_DISPLAY;
    },

    subPanelFlow: (state): void => {
      state.selectionType = SelectionType.SUB_DISPLAY;
    },

    selectionTypeSubDisplay: (state, action: PayloadAction<SubFlowType>): void => {
      state.subFlowType = action.payload;
    },

    handleBackButtonClick: (state): void => {
      state.selectionType = SelectionType.DEFAULT_DISPLAY;
    },

    handleCloseButtonClick: (state): void => {
      state.selectionType = SelectionType.DEFAULT_DISPLAY;
    },

    resetPanelState: (state): void => {
      state.selectionType = SelectionType.DEFAULT_DISPLAY;
      state.subFlowType = SubFlowType.DEFAULT;
      state.showLoader = false;
      state.showError = false;
    },

    setHashedId: (state, action: PayloadAction<string>): void => {
      state.hashedId = action.payload;
    },
  },
});

export const {
  setOrderDetails,
  setFreebieProduct,
  setDisplayType,
  setPremiumProductForPrint,
  setPremiumProductForPoster,
  setProductType,
  setOrderError,
  setErrorOnPublishPanel,
  setErrorMessageForPublishing,
  setShowLoader,
  selectionTypeSubDisplay,
  subPanelFlow,
  socialMediaFlow,
  webpageEmbedFlow,
  setSocialMediaLinks,
  shareToAppFlow,
  digitalSignageFlow,
  webpagePublishFlow,
  handleBackButtonClick,
  setHashedId,
  resetPanelState,
} = posterPublishingOptionsSlice.actions;
export const posterPublishingOptionsReducer = posterPublishingOptionsSlice.reducer;
