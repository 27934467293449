import type {ReactElement, ReactNode} from 'react';
import React, {useState} from 'react';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';
import {logClientError} from '@Libraries/log.library';
import {ErrorBoundaryFallbackContainer} from '@Components/error-boundary-fallback-container/error-boundary-fallback-container';

interface ModalErrorBoundaryProps {
  children: ReactElement | Array<ReactElement | ReactNode> | ReactNode;
  modalId: string;
}

export function ModalErrorBoundary({...props}: ModalErrorBoundaryProps): ReactElement | null {
  const [reload, setReload] = useState(false);

  const getFallbackUI = (): ReactElement => {
    return <ErrorBoundaryFallbackContainer onReload={onReload} />;
  };

  const onReload = (): void => {
    setReload(!reload);
  };

  const onError = (e: Error): void => {
    void logClientError(e, {
      reason: 'MODAL_ERROR_BOUNDARY',
    });
  };

  return (
    <ReactErrorBoundary resetKeys={[reload]} fallback={getFallbackUI()} onError={onError}>
      {props.children}
    </ReactErrorBoundary>
  );
}
