import type {ReactElement} from 'react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import type {GRID_ITEM_TYPE, GridItemProps} from '@Components/base-grid/components/grid-item';
import {GridItem} from '@Components/base-grid/components/grid-item';
import {InputField} from '@Components/input-field';
import {toggleGridItemSelection, updateGridItem} from '@Components/base-grid';
import {addChangeToLog} from '@Panels/manage-pmw-stock-panels/manage-pmw-stock-panels-reducer';
import {isEqual, omit} from 'lodash';
import {ManagePMWStockPanelType, PMWStockItemType} from '@Panels/manage-pmw-stock-panels/manage-pmw-stock-panels-types';
import {isSafari} from 'react-device-detect';
import {useIsInViewport} from '@Hooks/useIsInViewport';
import {Text, TextSize} from '@Components/text';
import {arraysEqual} from '@Utils/array.util';
import type {GridMediaItemStorage} from '../grid-media-item';
import styles from './grid-pmwstockmedia-item.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';

export interface GridPMWStockID {
  idPMmwStockMedia: string;
  idPMWStockAnimatedSticker?: string;
  idPMWStockShape?: string;
  idPMWStockStillSticker?: string;
  idPMWExtractedGettySticker?: string;
  idPMWStockIcon?: string;
  idPMWStockEmoji?: string;
  idPMWStockImage?: string;
}

export interface GridPMWStockMediaItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.PMW_STOCK_MEDIA;
  hashedFilename?: string;
  isHidden: number;
  keywords: string[];
  stockType: string;
  isEdited: boolean;
  isMask?: number;
  idPMWStock: GridPMWStockID;
  adminScore?: number;
  scrollToItem: boolean;
  iconCategory?: string;
}

interface GridPMWStockMediaItemProps extends GridItemProps {
  stockType: PMWStockItemType;
  thumbSrc: string;
  paddedThumb?: boolean;
  keywords: string[];
  isEdited: boolean;
  isDeleted?: boolean;
  scrollToItem: boolean;
  id: string;
  adminScore: number;
  iconCategory?: string;
}

export function GridPMWStockMediaItem({isEdited = false, isDeleted = false, style, ...props}: GridPMWStockMediaItemProps): ReactElement {
  const dispatch = useAppDispatch();
  const gridReduxState = useAppSelector((state) => {
    return state.grids.gridsHashmap[props.gridId ?? ''];
  });
  const changesReduxState = useAppSelector((state) => {
    return state.ManagePMWStockPanel.PMWStockChanges;
  });

  const selectedGridItemIds = useAppSelector((state) => {
    return state.grids.gridSelectionGroupHashmap[props.gridId ?? ''].selectedItemIdHashmap;
  });

  const [gridItem, setGridItem] = useState<HTMLDivElement | null>(null);
  const isInViewPort = useIsInViewport(gridItem as HTMLElement);
  const [, setIsStickerBackupImageLoaded] = useState(false);
  const [, setIsShapeImageLoaded] = useState(false);

  const gridItemRef = useRef<HTMLDivElement | null>(null);

  const [focusOnKeywordContainer, setFocusOnKeywordContainer] = useState(false);

  useEffect(() => {
    if (props.scrollToItem && gridItemRef.current) {
      gridItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    if (props.scrollToItem && props.gridId && !(props.id in selectedGridItemIds)) {
      dispatch(
        updateGridItem({
          gridId: props.gridId,
          itemId: props.id,
          changes: {scrollToItem: false},
        })
      );
    }
  }, [gridReduxState, selectedGridItemIds]);

  const handleOnChange = useCallback(
    (e: string, id: string): void => {
      let updatedKeywords = e.split(',').map((keyword) => {
        return keyword.trim().toLowerCase();
      });

      if (updatedKeywords.length === 1 && updatedKeywords[0] === '') {
        updatedKeywords = [];
      } else {
        // delete duplicate keywords
        updatedKeywords = Array.from(new Set(updatedKeywords));
      }

      const gridItems = gridReduxState.items as Array<GridPMWStockMediaItemStorage>;
      const itemToChange = gridItems.find((item: GridPMWStockMediaItemStorage): boolean => {
        return item.id === id;
      });

      if (itemToChange !== undefined) {
        let edited = true;
        let changedItem = {...itemToChange, keywords: updatedKeywords} as Partial<GridPMWStockMediaItemStorage>;

        if (arraysEqual(itemToChange.keywords, updatedKeywords)) {
          edited = false;
        }

        if (changesReduxState[itemToChange.id]?.original) {
          edited = true;
          const originalItem = omit(changesReduxState[itemToChange.id].original, 'isEdited') as Partial<GridPMWStockMediaItemStorage>;
          changedItem = omit(changedItem, 'isEdited');

          if (isEqual(originalItem, changedItem)) {
            edited = false;
          }
        }

        const changedItemToLog = {id: changedItem.id, keywords: changedItem.keywords, isEdited: edited} as Partial<GridPMWStockMediaItemStorage>;
        dispatch(addChangeToLog({changeToLog: changedItemToLog, itemToChange}));
        dispatch(
          updateGridItem({
            gridId: props.gridId ?? '',
            itemId: itemToChange.id,
            changes: {keywords: updatedKeywords, isEdited: edited},
          })
        );
        dispatch(
          updateGridItem({
            gridId: ManagePMWStockPanelType.PMW_ALL_STOCK,
            itemId: itemToChange.id,
            changes: {keywords: updatedKeywords, isEdited: edited},
          })
        );
      }
    },
    [dispatch, gridReduxState.items, changesReduxState, props.gridId]
  );

  const getKeywordsContainer = (): ReactElement => {
    const isItemSelected = props.id in selectedGridItemIds;

    return (
      <div className={`border-s-standard flex-vertical-center ${styles.keywordsContainer}`}>
        <div className={isItemSelected ? styles.itemOverlay : ''} />
        <InputField
          onSelect={(): void => {
            setFocusOnKeywordContainer(true);
          }}
          onClick={(e): void => {
            if (!isItemSelected) {
              e.stopPropagation();
            } else {
              dispatch(
                toggleGridItemSelection({
                  gridItemId: props.id,
                  gridSelectionGroupId: props.gridSelectionGroupId ?? '',
                  select: true,
                })
              );
            }
          }}
          onInputBlur={(e: string): void => {
            setFocusOnKeywordContainer(!focusOnKeywordContainer);
            return handleOnChange(e, props.id);
          }}
          multiLineRows={2}
          inputClassName={styles.textArea}
          showInputIcon={false}
          isMultiLine
          className={`_full-width body-s ${focusOnKeywordContainer ? styles.textAreaFocussed : styles.textArea}`}
          value={props.keywords.join(', ')}
        />
      </div>
    );
  };

  const getStickerThumbElement = (isInViewport: boolean): ReactElement => {
    if (isSafari) {
      return (
        <img
          onLoad={(): void => {
            return setIsStickerBackupImageLoaded(true);
          }}
          className={`flex-v-row flex-center radius-4 ${focusOnKeywordContainer ? styles.thumbItemShrinked : styles.thumbItem}`}
          draggable="false"
          crossOrigin="anonymous"
          src={isInViewport ? props.thumbSrc : ''}
          alt={window.i18next.t('pmwjs_sticker')}
        />
      );
    }
    return (
      <img
        className={`flex-v-row flex-center radius-4 ${focusOnKeywordContainer ? styles.thumbItemShrinked : styles.thumbItem}`}
        draggable="false"
        onLoad={(): void => {
          return setIsStickerBackupImageLoaded(true);
        }}
        crossOrigin="anonymous"
        src={isInViewport ? props.thumbSrc : ''}
        alt={window.i18next.t('pmwjs_sticker')}
      />
    );
  };

  const getShapeThumbElement = (): ReactElement => {
    return (
      <img
        onLoad={(): void => {
          return setIsShapeImageLoaded(true);
        }}
        src={props.thumbSrc}
        className={`flex-v-row flex-center radius-4 ${focusOnKeywordContainer ? styles.thumbItemShrinked : styles.thumbItem}`}
        alt={window.i18next.t('pmwjs_image')}
        draggable="false"
        loading="lazy"
      />
    );
  };

  const applyItemClassName = (isItemEdited: boolean, isItemDeleted: boolean): string => {
    if (isItemDeleted) {
      return styles.deletedItem;
    }
    if (isItemEdited) {
      return styles.editedItem;
    }
    return styles.ItemDefaultStyle;
  };

  return (
    <GridItem {...props} style={style} containerClassName={applyItemClassName(isEdited, isDeleted)}>
      {props.stockType === PMWStockItemType.ANIMATED_STICKER ? (
        <div
          ref={(element): void => {
            setGridItem(element);
            gridItemRef.current = element;
          }}
          className={`flex-center spacing-m-l-2 ${focusOnKeywordContainer ? styles.thumbContainerFocussed : styles.thumbContainer}`}
        >
          {React.cloneElement(getStickerThumbElement(isInViewPort))}
        </div>
      ) : (
        <div className={`flex-center spacing-m-l-2 ${focusOnKeywordContainer ? styles.thumbContainerFocussed : ''} ${styles.thumbContainer}`} ref={gridItemRef}>
          {getShapeThumbElement()}
        </div>
      )}
      <div className={styles.infoContainer}>
        {!focusOnKeywordContainer ? <Text val={`Score: ${props.adminScore}`} size={TextSize.XXSMALL} className={styles.adminScore} /> : null}
        {getKeywordsContainer()}
      </div>
    </GridItem>
  );
}
