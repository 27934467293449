import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import type {SocialShareThumbnailMeta} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import {EmailCampaignPublishOptionsSublow} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';

interface EmailCampaignPublishOptionsPanelState {
  campaignId: string;
  publishingSubflow: EmailCampaignPublishOptionsSublow;
  socialShareThumbnails: SocialShareThumbnailMeta | null;
}

const initialState: EmailCampaignPublishOptionsPanelState = {
  campaignId: '',
  publishingSubflow: EmailCampaignPublishOptionsSublow.MAIN_OPTIONS,
  socialShareThumbnails: null,
};

export const emailCampaignPublishOptionsPanelSlice = createSlice({
  name: 'emailCampaignPublishOptions',
  initialState,
  reducers: {
    initEmailPublishingDialog: (state, action: PayloadAction<string>): void => {
      state.campaignId = action.payload;
      state.publishingSubflow = EmailCampaignPublishOptionsSublow.MAIN_OPTIONS;
    },
    setSocialMediaSubflow: (state): void => {
      state.publishingSubflow = EmailCampaignPublishOptionsSublow.SOCIAL_MEDIA;
    },
    setEmailPublishingSubflow: (state): void => {
      state.publishingSubflow = EmailCampaignPublishOptionsSublow.EMAIL_CAMPAIGN;
    },
    setWebpagePublishingFlow: (state): void => {
      state.publishingSubflow = EmailCampaignPublishOptionsSublow.WEB_PAGE;
    },
    setPublishingOptionsFlow: (state): void => {
      state.publishingSubflow = EmailCampaignPublishOptionsSublow.MAIN_OPTIONS;
    },
    setSocialShareThumbnailMeta: (state, action: PayloadAction<SocialShareThumbnailMeta>): void => {
      state.socialShareThumbnails = action.payload;
    },
  },
});

export const {initEmailPublishingDialog, setEmailPublishingSubflow, setWebpagePublishingFlow, setSocialMediaSubflow, setPublishingOptionsFlow, setSocialShareThumbnailMeta} =
  emailCampaignPublishOptionsPanelSlice.actions;

export const emailCampaignPublishOptionsPanelSliceReducer = emailCampaignPublishOptionsPanelSlice.reducer;
