import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import type {ConfirmDeletePanelProps} from '@Panels/confirm-delete-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'confirm-delete-modal-panel';

export type ConfirmDeleteModalProps = ConfirmDeletePanelProps;

export default function ConfirmDeleteModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.CONFIRM_DELETE_MODAL];
  });

  const height = '450px';
  const width = '560px';

  return (
    <Modal modalId={MODALS_IDS.CONFIRM_DELETE_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="confirm-delete-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openConfirmDeleteModal = (props: ConfirmDeleteModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.CONFIRM_DELETE_MODAL,
      panelProps: props,
    })
  );
};
