import {configureStore} from '@reduxjs/toolkit';

import {loadingToastReducer} from '@Components/loading-toast';
import {messageGrowlReducer} from '@Components/message-growl';
import {popupModalReducer} from '@Components/popup-modal/popup-modal-reducer';
import {downloadPanelReducer} from '@Components/download-publish-modal/download-publish-modal-reducer';
import {brandsReducer} from '@Components/mystuff-brands/brands-reducer';
import {myStuffReducer} from '@Components/mystuff-content/my-stuff-slice';
import {fontSelectorReducer} from '@Components/font-selector/font-selector-slice';
import {langSelectorReducer} from '@Components/language-selector/language-selector-slice';
import {emailMarketingCampaignsPerformanceReducer} from '@Components/email-marketing-campaign-performance/email-marketing-campaign-performance-reducer';
import {emailMarketingCampaignSettingsReducer} from '@Components/email-marketing-campaign-settings/email-marketing-campaign-settings-reducer';
import {emailMarketingCampaignAudienceReducer} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';
import {removeBackgroundReducer} from '@Panels/cropper-panel/components/remove-background-icon-box/remove-background-reducer';
import {baseGridReducer} from '@Components/base-grid';
import {gridAudioItemReducer} from '@Components/base-grid/components/grid-user-audio-item/grid-audio-item-reducer';
import {listReducer} from '@Components/list/list-reducer';
import {accordionReducer} from '@Components/accordion-deprecated/accordion-reducer';
import {contentPlannerReducer} from '@Components/content-planner/content-planner-reducer';
import {modalContainerReducer} from '@Components/modal-container/modal-container-reducer';
import {posterEditorReducer} from '@Components/poster-editor/poster-editor-reducer';
import {lazyPanelReducer} from '@Components/lazy-panel/lazy-panel-reducer';
import {menusReducer} from '@Components/panels/user-menu-panel/user-menu-panel-reducer';
import {tabsReducer} from '@Components/tabs/tabs-reducer';
import {loginPageReducer} from '@Components/login-page/login-page-reducer';
import {designerEarningsReducer} from '@Components/designer-earnings-report/designer-earnings-report-reducer';
import {panelReducer} from '@Components/panel/panel-reducer';
import {trimReducer} from '@Panels/trim-panels/trim-panels-reducer';
import {tableReducer} from '@Components/table/table.reducer';
import {PosterPopUpMenuReducer} from '@Components/poster-editor/components/poster-popup-menu/poster-popup-menu-slice';
import {changePlanModalReducer} from '@Panels/change-plan-modal/change-plan-modal-reducer';
import {TextItemPopUpMenuReducer} from '@Components/poster-editor/components/text-item-popup/text-item-popup-slice';
import {shapeMaskingReducer} from '@Panels/masking-panel/components/shape-masking-panel/shape-masking-panel-reducer';
import {fancyTextReducer} from '@Panels/add-fancytext-panel/add-fancy-text-container.reducer';
import {socialMediaGridsReducer} from '@Components/social-media/social-media-reducer';
import {saveReminderReducer} from '@Components/save-reminder-notification/save-reminder-notification.reducer';
import {spellCheckReducer} from '@Components/spell-check/spell-check-slice';
import {freehandMaskingReducer} from '@Panels/masking-panel/components/freehand-masking-panel/freehand-masking-panel-reducer';
import {maskingReducer} from '@Panels/masking-panel/masking-panel-reducer';
import {textMaskingReducer} from '@Panels/masking-panel/components/text-masking-panel/text-masking-panel-reducer';
import {managePmwStockReducer} from '@Panels/manage-pmw-stock-panels/manage-pmw-stock-panels-reducer';
import {uploadsReducer} from '@Panels/user-media-panel/user-media-panel-reducer';
import {socialPostDialogReducer} from '@Panels/social-posts-dialog-panel/social-posts-dialog-panel.reducer';
import {socialMediaWizardReducer} from '@Components/social-media-wizard/social-media-wizard-reducer';
import {imageItemPopUpMenuReducer} from '@Components/poster-editor/components/image-item-popup/image-item-popup-slice';
import {stockReducer} from '@Panels/stock-panel/stock-panel-reducer';
import {editorMobileOnboardingReducer} from '@Components/poster-editor/components/editor-mobile-onboarding/editor-mobile-onboarding.reducer';
import {autoCaptionReducer} from '@Components/auto-caption-prompt-box/auto-caption-reducer';
import {recordImageAndVideoReducer} from '@Panels/record-image-and-video-panel/record-image-and-video-panel-reducer';
import {designerAttributionReducer} from '@Components/poster-editor/components/poster-editing-side-panel/components/designer-attribution/designer-attribution.reducer';
import {copperPanelReducer} from '@Panels/cropper-panel/cropper-panel-reducer';
import {videoBgRemovalCreditsCostReducer} from '@Components/video-bg-removal-credits-cost/video-bg-removal-credits-cost-slice';
import {teamCreditsReducer} from '@Components/team-credits/team-credits-slice';
import {templateSubmissionFlowReducer} from '@Components/template-submission-flow/template-submission-flow-reducer';
import {imageBgRemovalCreditsCostReducer} from '@Components/image-bg-removal-credits-cost/image-bg-removal-credits-cost-slice';
import {twoFactorAuthenticationReducer} from '@Components/two-factor-authentication/two-factor-authentication-reducer';
import {emailMarketingCampaignsReducer} from '@Components/email-marketing-campaigns/email-marketing-campaigns-slice';
import {socialMediaSelectCoverPanelReducer} from '@Panels/social-media-select-cover-panel/social-media-select-cover-panel-reducer';
import {posterEditorWebBottomBarReducer} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.reducer';
import {posterPublishingOptionsReducer} from '@Panels/poster-publishing-options-panel/poster-publishing-options-slice';
import {googleMapReducer} from '@Components/google-map/google-map-slice';
import {userOnlineStatusReducer} from '@/reducers/user-online-status.reducer';
import {businessProfilesWizardReducer} from '@Components/business-profiles-wizard/business-profiles-wizard-reducer';
import {emailCampaignPublishOptionsPanelSliceReducer} from '@Components/email-marketing-campaigns/email-campaign-publish-options.reducer';

const store = configureStore({
  reducer: {
    accordion: accordionReducer,
    audioGridItem: gridAudioItemReducer,
    autoCaption: autoCaptionReducer,
    brands: brandsReducer,
    contentPlanner: contentPlannerReducer,
    changePlanModal: changePlanModalReducer,
    designerEarnings: designerEarningsReducer,
    downloadPanel: downloadPanelReducer,
    editorMobileOnboarding: editorMobileOnboardingReducer,
    emailMarketingCampaignsPerformance: emailMarketingCampaignsPerformanceReducer,
    emailMarketingCampaignSettings: emailMarketingCampaignSettingsReducer,
    emailMarketingCampaignAudience: emailMarketingCampaignAudienceReducer,
    fontSelector: fontSelectorReducer,
    freehandMasking: freehandMaskingReducer,
    fancyText: fancyTextReducer,
    grids: baseGridReducer,
    imageBgRemovalCreditsCost: imageBgRemovalCreditsCostReducer,
    imageItemPopUpMenu: imageItemPopUpMenuReducer,
    list: listReducer,
    userOnlineStatus: userOnlineStatusReducer,
    langSelector: langSelectorReducer,
    loadingToast: loadingToastReducer,
    lazyPanels: lazyPanelReducer,
    loginPage: loginPageReducer,
    masking: maskingReducer,
    ManagePMWStockPanel: managePmwStockReducer,
    modals: modalContainerReducer,
    messageGrowl: messageGrowlReducer,
    mediaUploads: uploadsReducer,
    menus: menusReducer,
    myStuff: myStuffReducer,
    popupModal: popupModalReducer,
    posterEditor: posterEditorReducer,
    posterPopUpMenu: PosterPopUpMenuReducer,
    panels: panelReducer,
    removeBackgroundCredits: removeBackgroundReducer,
    shapeMasking: shapeMaskingReducer,
    saveReminder: saveReminderReducer,
    spellCheck: spellCheckReducer,
    socialMediaGrid: socialMediaGridsReducer,
    socialMediaWizard: socialMediaWizardReducer,
    stockPanel: stockReducer,
    socialPostDialog: socialPostDialogReducer,
    selectCoverPanel: socialMediaSelectCoverPanelReducer,
    table: tableReducer,
    tabs: tabsReducer,
    teamCredits: teamCreditsReducer,
    trimPanel: trimReducer,
    cropperPanel: copperPanelReducer,
    textItemPopUpMenu: TextItemPopUpMenuReducer,
    textMasking: textMaskingReducer,
    designerAttribution: designerAttributionReducer,
    recordImageAndVideoPanel: recordImageAndVideoReducer,
    videoBgRemovalCreditsCost: videoBgRemovalCreditsCostReducer,
    templateSubmissionFlow: templateSubmissionFlowReducer,
    twoFactorAuthentication: twoFactorAuthenticationReducer,
    emailMarketingCampaigns: emailMarketingCampaignsReducer,
    publishingOptionsPanel: posterPublishingOptionsReducer,
    posterEditorWebBottomBar: posterEditorWebBottomBarReducer,
    googleMap: googleMapReducer,
    emailCampaignPublishOptions: emailCampaignPublishOptionsPanelSliceReducer,
    businessProfilesWizard: businessProfilesWizardReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ReduxThunkReturnType = ReturnType<AppDispatch>;
export default store;
