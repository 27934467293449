import type {ReactElement} from 'react';
import React from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import {ClickableDiv} from '@Components/clickable-div';
import styles from './pill.module.scss';
import {Text, TextSize, TextType} from '../text';
import {Icon} from '../icon-v2';
import {noop} from '@Utils/general.util';

export interface PillProps {
  content: PillContent;
  className?: string;
  size?: PILL_SIZE;
  isFlat?: boolean;
  isDisabled?: boolean;
  background?: PILL_BACKGROUND_COLOR | string;
  theme?: PILL_THEME;
  isActive?: boolean;
  isHoverable?: boolean;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

export enum PILL_THEME {
  NEUTRAL = 'NEUTRAL',
  PRIMARY = 'PRIMARY',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  PREMIUM = 'PREMIUM',
}

export enum PILL_BACKGROUND_COLOR {
  PRIMARY_10 = 'PRIMARY_10',
  PRIMARY_20 = 'PRIMARY_20',
  PREMIUM_10 = 'PREMIUM_10',
  SECONDARY_10 = 'SECONDARY_10',
  SECONDARY_100 = 'SECONDARY_100',
  NEUTRAL_3 = 'NEUTRAL_3',
  TRANSPARENT = 'TRANSPARENT',
}

export enum PILL_SIZE {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type PillContent = IconContent | TextContent | HybridContent;

interface IconContent extends BasePillContent {
  type: PILL_CONTENT_TYPE.ICON;
  icon: string;
  size: IconSize;
}

interface TextContent extends BasePillContent {
  type: PILL_CONTENT_TYPE.TEXT;
  text: string;
  textClasses?: string;
  textColor?: string;
  textSize?: TextSize;
  isBold?: boolean;
}

interface HybridContent extends BasePillContent {
  type: PILL_CONTENT_TYPE.HYBRID;
  textSize?: TextSize;
  text: string;
  textColor?: string;
  isBold?: boolean;
  icon: string;
  iconColor?: string;
  iconClasses?: string;
}

interface BasePillContent {
  type: PILL_CONTENT_TYPE;
  classname?: string;
}

export enum PILL_CONTENT_TYPE {
  ICON = 'icon',
  TEXT = 'text',
  HYBRID = 'hybrid',
}

export function Pill({
  size = PILL_SIZE.MEDIUM,
  className = '',
  onClick = noop,
  onMouseEnter = noop,
  onMouseLeave = noop,
  isFlat = true,
  isDisabled = false,
  background = PILL_BACKGROUND_COLOR.PRIMARY_10,
  theme = undefined,
  isActive = false,
  isHoverable = false,
  ...props
}: PillProps): ReactElement {
  const getSizeClasses = (): string => {
    if (size === PILL_SIZE.XSMALL) {
      return styles.xsmall;
    }
    if (size === PILL_SIZE.SMALL) {
      return styles.small;
    }
    if (size === PILL_SIZE.MEDIUM) {
      return styles.medium;
    }
    if (size === PILL_SIZE.LARGE) {
      return styles.large;
    }
    return '';
  };

  const getThemeClasses = (): string => {
    if (typeof theme === 'undefined') {
      return '';
    }

    switch (theme) {
      case PILL_THEME.NEUTRAL:
        return styles.themeNeutral;
      case PILL_THEME.PRIMARY:
        return styles.themePrimary;
      case PILL_THEME.SUCCESS:
        return styles.themeSuccess;
      case PILL_THEME.DANGER:
        return styles.themeDanger;
      case PILL_THEME.PREMIUM:
        return styles.themePremium;
      default:
        return '';
    }
  };

  const getBackgroundColorClasses = (): string => {
    if (isDisabled) {
      return styles.neutral3;
    }
    if (isFlat) {
      switch (background) {
        case PILL_BACKGROUND_COLOR.PRIMARY_20:
          return styles.primary20;
        case PILL_BACKGROUND_COLOR.PREMIUM_10:
          return styles.premium10;
        case PILL_BACKGROUND_COLOR.SECONDARY_10:
          return styles.secondary10;
        case PILL_BACKGROUND_COLOR.SECONDARY_100:
          return styles.secondary100;
        case PILL_BACKGROUND_COLOR.NEUTRAL_3:
          return styles.neutral3;
        case PILL_BACKGROUND_COLOR.TRANSPARENT:
          return styles.transparent;
        case PILL_BACKGROUND_COLOR.PRIMARY_10:
          return styles.primary10;
        default:
          return background;
      }
    }
    return styles.pillBackground;
  };

  const getPillClasses = (): string => {
    return [className, styles.container, getSizeClasses(), getBackgroundColorClasses(), getThemeClasses(), getActiveClass(), getHoverClasses()].join(' ');
  };

  const getActiveClass = (): string => {
    return isActive ? styles.isActive : '';
  };

  const getHoverClasses = (): string => {
    if (typeof theme === 'undefined' || isHoverable === false) {
      return '';
    }

    switch (theme) {
      case PILL_THEME.NEUTRAL:
        return styles.neutralHover;
      case PILL_THEME.PRIMARY:
        return styles.primaryHover;
      case PILL_THEME.SUCCESS:
        return styles.successHover;
      case PILL_THEME.DANGER:
        return styles.dangerHover;
      case PILL_THEME.PREMIUM:
        return styles.premiumHover;
      default:
        return '';
    }
  };

  const displayContent = (): ReactElement | null => {
    if (props.content.type === PILL_CONTENT_TYPE.ICON) {
      return <Icon icon={props.content.icon} size={props.content.size} className={`${styles.pillText}`} isDisabled />;
    }
    if (props.content.type === PILL_CONTENT_TYPE.TEXT) {
      return (
        <Text
          val={props.content.text}
          size={props.content.textSize ?? TextSize.XXSMALL}
          className={`${styles.pillText} ${isDisabled ? 'content-disabled' : props.content.textColor ?? 'content-primary'} ${props.content.textClasses as string}`}
          bold={props.content.isBold ?? true}
        />
      );
    }
    if (props.content.type === PILL_CONTENT_TYPE.HYBRID) {
      return (
        <div className="flex-center">
          <Icon
            icon={props.content.icon}
            className={`spacing-m-r-2 ${props.content.iconClasses} ${isDisabled ? 'content-disabled' : props.content.iconColor ?? 'content-body'}`}
            size={IconSize.SIZE_ICON_16}
          />
          <Text
            val={props.content.text}
            size={props.content.textSize ?? TextSize.XSMALL}
            bold={props.content.isBold}
            type={TextType.BODY}
            className={`${styles.pillText} ${isDisabled ? 'content-disabled' : props.content.textColor ?? 'content-body'}`}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <ClickableDiv onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`${getPillClasses()}`} onClick={onClick}>
      {displayContent()}
    </ClickableDiv>
  );
}
