import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {getTeamMembers, initChangePlanModal} from '@Panels/change-plan-modal/change-plan-modal-thunk';
import type {ChangePlanModalState, IAPChangePlanState} from '@Panels/change-plan-modal/change-plan-modal.types';
import {CurrencyCode} from '@Utils/currency.util';
import {isIOSApp} from '@Utils/browser.util';

const initialState: ChangePlanModalState = {
  plans: [],
  isReady: false,
  isTrial: false,
  currentSeats: 1,
  currentSeatLimit: 1,
  promoCode: '',
  currentPlanId: 0,
  currentLevel: 0,
  currentDuration: 1,
  currentDisplayPrice: 0,
  selectedLevel: 0,
  selectedTierId: 0,
  selectedDuration: 1,
  currencyCode: CurrencyCode.USD,
  teamMembers: [],
  selectedSeats: 0,
  memberIdsToRemove: [],
  currentTierId: null,
  cancelPlanMessage: '',
  defaultCardBrand: '',
  defaultCardLast4: '',
  manageCardsURL: '',
};

export const changePlanModalSlice = createSlice({
  name: 'changePlanModal',
  initialState,
  reducers: {
    updateSelectedTier: (state, {payload}: PayloadAction<number>) => {
      state.selectedTierId = payload;
    },
    updateSelectedLevel: (state, {payload}: PayloadAction<number>) => {
      state.selectedLevel = payload;
    },
    updateSelectedDuration: (state, {payload}: PayloadAction<number>) => {
      state.selectedDuration = payload;
    },
    updateSelectedSeats: (state, {payload}: PayloadAction<number>) => {
      state.selectedSeats = payload;
    },
    updateStateForIAP: (state, {payload}: PayloadAction<IAPChangePlanState>) => {
      state.plans = payload.plans;
      state.selectedLevel = payload.selectedLevel;
      state.selectedDuration = payload.selectedDuration;
      state.selectedSeats = payload.selectedSeats;
      state.selectedTierId = payload.selectedTierId;
      state.currentTierId = payload.currentTierId;
      state.isReady = true;
    },
    selectMemberToRemove: (state, {payload}: PayloadAction<number>) => {
      if (!state.memberIdsToRemove.includes(payload)) {
        state.memberIdsToRemove.push(payload);
      }
    },
    deselectMemberToRemove: (state, {payload}: PayloadAction<number>) => {
      const index = state.memberIdsToRemove.indexOf(payload);
      if (index > -1) {
        state.memberIdsToRemove.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initChangePlanModal.pending, (state) => {
      state.isReady = false;
    });

    builder.addCase(initChangePlanModal.fulfilled, (state, {payload}: PayloadAction<ChangePlanModalState>) => {
      const {plans, currentLevel, currentDuration, currentSeatLimit} = payload;

      state.isTrial = payload.isTrial;
      state.promoCode = payload.promoCode;
      state.currencyCode = payload.currencyCode;
      state.plans = plans;

      state.currentSeatLimit = currentSeatLimit;
      state.currentSeats = payload.currentSeats;
      state.currentPlanId = payload.currentPlanId;
      state.currentLevel = currentLevel;
      state.currentDuration = currentDuration;
      state.currentDisplayPrice = payload.currentDisplayPrice;
      state.currentTierId = payload.currentTierId;
      state.cancelPlanMessage = payload.cancelPlanMessage;

      state.selectedLevel = payload.currentLevel;
      state.selectedTierId = payload.selectedTierId;
      state.selectedDuration = payload.selectedDuration;
      state.selectedSeats = currentSeatLimit;

      if (Object.prototype.hasOwnProperty.call(payload, 'defaultCardBrand') && Object.prototype.hasOwnProperty.call(payload, 'defaultCardLast4')) {
        state.defaultCardBrand = payload.defaultCardBrand;
        state.defaultCardLast4 = payload.defaultCardLast4;
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'manageCardsURL')) {
        state.manageCardsURL = payload.manageCardsURL;
      }

      state.isReady = true;
      if (!isIOSApp()) {
        state.isReady = true;
      }
    });

    builder.addCase(getTeamMembers.pending, (state) => {
      state.isReady = false;
    });

    builder.addCase(getTeamMembers.fulfilled, (state, {payload}) => {
      state.teamMembers = Object.values(payload);
      state.isReady = true;
    });
  },
});

export const {updateSelectedTier, updateSelectedLevel, updateSelectedDuration, updateSelectedSeats, updateStateForIAP, selectMemberToRemove, deselectMemberToRemove} =
  changePlanModalSlice.actions;
export const changePlanModalReducer = changePlanModalSlice.reducer;
