import type {ForwardedRef, MouseEvent, ReactElement} from 'react';
import React from 'react';
import {isValidURL} from '@Utils/url.util';
import {noop} from '@Utils/general.util';
import style from './text.module.scss';

export interface TextProps {
  val: string;
  size?: TextSize;
  type?: TextType;
  className?: string;
  bold?: boolean;
  underline?: boolean;
  href?: string;
  onClick?: (event: MouseEvent) => void;
  dangerouslySetInnerHTML?: boolean;
  /**
   * a key value pair of any attributes we want to give to the root component such as data-id etc
   */
  attributes?: Record<string, string>;
  disableSelection?: boolean;
  /**
   * Set this to enable click tracking for this text element on GA4.
   */
  ga4ClickName?: string;
  title?: string;
}

export enum TextSize {
  XXXSMALL = 'xxxsmall',
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum TextType {
  BODY = 'body',
  CAPTION = 'caption',
}

export const Text = React.forwardRef<HTMLAnchorElement | HTMLSpanElement, TextProps>(
  (
    {bold = false, size = TextSize.MEDIUM, type = TextType.BODY, className = '', href = '', onClick = noop, attributes = {}, dangerouslySetInnerHTML = false, ...props},
    ref
  ): ReactElement => {
    const getClassForSize = (): string => {
      let textClassForSize: string = type;
      switch (size) {
        case TextSize.XXXSMALL:
          textClassForSize += '-xxxs';
          break;
        case TextSize.XXSMALL:
          textClassForSize += '-xxs';
          break;
        case TextSize.XSMALL:
          textClassForSize += '-xs';
          break;
        case TextSize.SMALL:
          textClassForSize += '-s';
          break;
        case TextSize.MEDIUM:
          textClassForSize += '-m';
          break;
        case TextSize.LARGE:
          textClassForSize += '-l';
          break;
        case TextSize.XLARGE:
          textClassForSize += '-xl';
          break;
        default:
          throw new Error(`Unkown type provided for Text size: ${size}`);
      }

      if (bold) {
        return `${textClassForSize}-bold`;
      }

      return textClassForSize;
    };

    const isLink = (): boolean => {
      return href !== '' && isValidURL(href);
    };

    const attachHrefIfApplicable = (): void => {
      if (isLink()) {
        attributes.href = href;
      }
    };

    const getClassesForStyles = (): string => {
      return `${props.underline ? style.underLine : ''} ${props.disableSelection ? style.disableSelection : ''}`;
    };

    const onElClick = (e: MouseEvent): void => {
      if (typeof window.PMW.gtm !== undefined && props.ga4ClickName !== undefined && props.ga4ClickName !== '') {
        window.PMW.gtm.trackGA4CustomClick(props.ga4ClickName);
      }
      onClick(e);
    };

    const getTextComponent = (): ReactElement => {
      if (dangerouslySetInnerHTML) {
        return isLink() ? (
          <a
            title={props.title}
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            onClick={onElClick}
            {...attributes}
            className={`${getClassForSize()} ${className} ${getClassesForStyles()}`}
            dangerouslySetInnerHTML={{__html: props.val}}
          />
        ) : (
          <span
            title={props.title}
            ref={ref as ForwardedRef<HTMLSpanElement>}
            onClick={onElClick}
            {...attributes}
            className={`${getClassForSize()} ${className} ${getClassesForStyles()}`}
            dangerouslySetInnerHTML={{__html: props.val}}
          />
        );
      }
      return isLink() ? (
        <a
          title={props.title}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          onClick={onClick}
          {...attributes}
          className={`${getClassForSize()} ${className} ${getClassesForStyles()}`}
        >
          {props.val}
        </a>
      ) : (
        <span
          title={props.title}
          ref={ref as ForwardedRef<HTMLSpanElement>}
          onClick={onClick}
          {...attributes}
          className={`${getClassForSize()} ${className} ${getClassesForStyles()}`}
        >
          {props.val}
        </span>
      );
    };

    attachHrefIfApplicable();

    return getTextComponent();
  }
);
